<template lang="pug">
  .fpa-wrapper
    .fpa-content
      video(:class="{fail: fail}" ref="video" width="100px" loop :autoplay="animated" muted="true" @timeupdate="timeupdate" poster="./output.jpg")
        source(src="./output.mp4" type="video/mp4")
</template>

<script>

  export default {
    props: ["animated", "fail"],
    mixins: [],
    components: {},
    methods: {
     timeupdate(e){
        this.timeStamp += 1
        if (this.timeStamp > 8 && !this.tiggert){
          this.timeStamp = 0
          this.tiggert = true
          this.$emit('atHalfAnimation')
        }
      },      
    },
    watch:{
      animated(){
        this.tiggert = false
        this.$refs.video.load()
      }
    },
    computed: {
    },
    mounted() {
    },
    data() {
      return {
        tiggert: false,
        timeStamp: 0
      }
    }
  }
</script>
<style scope>
.fpa-content{
  width: 120px;
  height: 120px;
  margin-bottom: 35px;
  border-radius: 16px;
  box-shadow: 0 0 32px 0 rgb(0 0 0 / 50%);
  padding: 10px;
}
.fpa-content video, .fpa-wrapper{
  display: block;
  text-align: -webkit-center;
}
.fail {
  animation: shake 2s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>