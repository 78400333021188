import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    fingerPrintData: {},
    localSettings: null,
    token: null,
    modalOpen: false
  },
  getters: {
    settings(state){
      return state.localSettings || {}
    }
  },
  mutations: {
    setModalOpen(state, modalOpen){
      state.modalOpen = modalOpen
    },
    setToken(state, token){
      state.token = token
    },
    updateSettings(state, settings){
      state.localSettings = settings
    },
    setSetting(state, obj){
      let settings = state.localSettings||{}
      settings[obj.name] = obj.value
      state.localSettings = settings
    },
    setUser(state, user){
      state.user = user
    },
    setFingerPrintData(state, fingerPrintData){
      state.fingerPrintData = fingerPrintData
    }
  },
  actions: {
  },
})

export default store
