require("application/application.scss")
require('bootstrap');

import Vue from 'vue/dist/vue.esm.js';
import FingerPrinter from '../time_tracking/views/FingerPrinter.vue';
import Store from '../time_tracking/Store';
import ActionCableVue from 'actioncable-vue';

import {BootstrapVue} from 'bootstrap-vue'
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: window.ConnectionUrl, // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: true,
});

Vue.use(BootstrapVue)

$(document).ready(function(){
  let element
  element = document.getElementById("finger_printer")
  if (element != null) {
    var vueapp = new Vue({
      el: "#finger_printer",
      store: Store,
      template: '<App/>',
      render: h => h(FingerPrinter, {
        props: element.dataset
      }),
    });
  }
});