const mixin = {
  methods: {
    subscribeChannel(channel, channelToken){
      this.$cable.subscribe({
        channel: channel,
        token: channelToken || window.ChannelToken
      });
    },
    onNotification(data){
      if (data.devices.indexOf("web") != -1 || (window.OnScreen && data.devices.indexOf("screen") != -1)){
        if (data.action === "hardreload" || (window.OnScreen && data.action === "reload")){
          location.reload();
        }else if(data.action === "reload"){
          if (confirm(data.msg || "Diese Seite muss neugeladen werden!")) {
            location.reload();
          }
        }else if(data.action === "alert" && !window.OnScreen){
          alert(data.msg)
        }
      }
    },
    csrfToken(){
      return document.querySelector('meta[name="csrf-token"]').content
    },
    deleteObj(path, id){
      return fetch('/api/admin/'+path+'/'+id+'.json', {
        method: 'DELETE',
      })      
    },
    callWithFormData(method, url, formData){
      return fetch(url, {
        headers: {
          'X-CSRF-TOKEN': this.csrfToken()
        },
        body: formData,
        method: method
      }).then(response => response.json());
    },
    call(method, url, params, web_token){
      let headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.csrfToken()
      }

      if(web_token){
        headers["Authorization"] = "Bearer " + web_token  
      }

      return fetch(url, {
        method: method.toUpperCase(),
        headers: headers,
        body: JSON.stringify(params)
      }).then(response => response.json())
    },
    dataFetch(url, params){
      return this.apiFetch(url, params).then(response => response.json())
    },
    apiFetch(url, params){
      params = params || {}
      if (window.ScreenToken){
        params.screen_token = window.ScreenToken
      }
      const queryString = [];
      for (const key in params) {
        queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
      return fetch(`${url}?${queryString.join('&')}`)
    },
    toFormData(obj, form, namespace) {
      let fd = form || new FormData();
      let formKey;
      
      for(let property in obj) {
        if(obj.hasOwnProperty(property)) {
          if (namespace) {
            formKey = namespace + '[' + property + ']';
          } else {
            formKey = property;
          }

          if (obj[property] instanceof Array) {
            obj[property].forEach((element, index) => {
              const tempFormKey = `${formKey}[${index}]`;
              if (typeof element === 'object') this.toFormData(element, fd, tempFormKey);
              else fd.append(formKey+'[]', element);
            });
          } else if (obj[property] instanceof Date) {
            fd.append(formKey, obj[property].toISOString());
          } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
            this.toFormData(obj[property], fd, formKey);
          } else if ( obj[property] != undefined ){ // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }
        }
      }
      
      return fd;
    }
  }
};

export default mixin
