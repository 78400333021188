<template lang="pug">
  div(v-if="settings.timetracking_login == 'true'")
    finger-print-animation(:animated="animated" @atHalfAnimation="atHalfAnimation" :fail="fingerPrintFail").mt-5
    b-alert(variant="info" :show="!fingerPrintFail")
      b-spinner(type="grow" small style="margin-bottom: 2px; margin-right: 10px;")
      | Finger bitte auf den Scanner auflegen.
    b-alert(variant="danger" :show="fingerPrintFail")
      i.fa.fa-times(style="margin-right: 10px;")
      | Fingerabdruck nicht gültig,
      div(style="margin-left: 21px;") bitte versuche es erneut.
    .text-center
      .h5(style="color: #d0d0d0; margin-top: 60px; margin-bottom: 15px;") Anmelden ohne Fingerabdruck
</template>

<script>
  import Api from '../../mixins/Api'
  import FingerPrintAnimation from '../components/FingerPrintAnimation/'
  import { mapState, mapMutations, mapGetters } from 'vuex'
  export default {
    props: ['timetracking_login', 'token', 'channel_token'],
    mixins: [Api],
    components: {FingerPrintAnimation},
    methods: {
      atHalfAnimation(){
        if(this.fingerPrintFailTimeout) clearTimeout(this.fingerPrintFailTimeout)
        this.animated = false
        this.fingerPrintFail = true
        this.fingerPrintFailTimeout = setTimeout(() =>{
          this.fingerPrintFail = false
        }, 5000);
      },
      ...mapMutations(['setSetting', 'updateSettings', 'setFingerPrintData', 'setToken'])
    },
    computed: {
      ...mapGetters(['settings']),
      ...mapState(['user', 'fingerPrintData'])
    },
    channels: {
      NotificationChannel: {
        received(data) {
          this.onNotification(data)
        }
      },
      SettingsChannel: {
        received(data) {
          this.updateSettings(data)
        }
      },
      FingerPrinterChannel: {
        received(data) {
          this.setFingerPrintData(data)
        }
      },
    },
    watch: {
      fingerPrintData(){
        this.fingerPrintFail = false
        if (this.fingerPrintData.valid && !this.fingerPrintData.is_new){
          this.call("POST", "/api/tokenlogin", {
            user_token: this.fingerPrintData.user.token,
          }).then(data => {
            console.log(data)
            location.href = "/"
          })
        }else{
          this.animated = true
        }
      }
    },
    mounted() {
      this.setSetting({name: 'timetracking_login', value: this.timetracking_login})
      this.setToken(this.token)
      this.subscribeChannel('FingerPrinterChannel', this.channel_token)
      this.subscribeChannel('SettingsChannel')
      this.subscribeChannel('NotificationChannel')
    },
    data() {
      return {
        fingerPrintFailTimeout: null,
        fingerPrintFail: false,
        animated: false,
      }
    }
  }
</script>
<style scope>
</style>